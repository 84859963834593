import { useState } from 'react';

import {
  faBrowsers,
  faCirclePhone,
  faEnvelope,
  faHeadset,
  faHourglass,
  faMessageSmile,
  faShieldCheck
} from '@fortawesome/pro-duotone-svg-icons';
import { faBrowsers as farBrowsers, faShieldCheck as farShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { faCloud, faKey } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useConfigContext } from 'lib/core/config';
import { useStreamsContext } from 'lib/core/context/StreamsProvider';

import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import Button from 'lib/common/components/Button';
import IconConstellation from 'lib/common/components/IconConstellation';
import { InfoCard, InfoCardGroup } from 'lib/common/components/InfoCard';
import Text from 'lib/common/components/Text';

import { HELP_LINKS } from 'lib/common/constants/help';

import styles from './hello.module.scss';

const LOGIN_HELP_TIMEOUT_S = 10000;

const Main = ({ name, sso }: { name?: string; sso: boolean }) => {
  const { initializeCCP } = useStreamsContext();
  const {
    config: {
      BRAND: { helpDocsUrl }
    }
  } = useConfigContext();
  const isSoftphone = useIsSoftphoneQuery();

  const [loading, setLoading] = useState(false);
  const [extendedLoad, setExtendedLoad] = useState(false);

  return (
    <>
      <div>
        <Text type="heading1">{name ? `Welcome back, ${name}!` : 'Hello!'}</Text>
        <Text color="darkGrey">
          We'll start off by getting you to sign in to {sso ? 'your provider' : 'Amazon Connect'}.{' '}
          <Text type="link" inline href={`${helpDocsUrl}${HELP_LINKS.CONNECT.SIGN_IN}`}>
            Why?
          </Text>
        </Text>
      </div>

      <InfoCardGroup
        minimal={isSoftphone}
        cards={[
          {
            title: 'Pop-ups',
            icon: isSoftphone ? farBrowsers : faBrowsers,
            text: (
              <Text>
                The sign in page will open in a new window or tab, so you'll need to{' '}
                <Text type="link" href={`${helpDocsUrl}${HELP_LINKS.CONNECT.WINDOW_NOT_APPEARING}`} inline>
                  allow popups
                </Text>
                .
              </Text>
            )
          },
          {
            title: 'Next Steps',
            icon: isSoftphone ? farShieldCheck : faShieldCheck,
            text: `After signing into ${
              sso ? 'your provider' : 'Amazon Connect'
            }, the window will close, and we'll continue automatically.`
          }
        ]}
      />

      <div className={cx(styles['hello__extended-load'], { [styles['hello__extended-load--expanded']]: extendedLoad })}>
        <InfoCard
          type="SECONDARY"
          minimal={isSoftphone}
          title="Still Going"
          icon={faHourglass}
          text={
            <Text>
              We're working hard to sign you in! If you've been waiting for some time you can try{' '}
              <Text type="underline" inline>
                reloading the page
              </Text>
              .
            </Text>
          }
        />
      </div>

      <Button
        onClick={async () => {
          setLoading(true);
          setTimeout(() => {
            setExtendedLoad(true);
          }, LOGIN_HELP_TIMEOUT_S);
          await initializeCCP();
        }}
        busy={loading}
        icon={sso ? faKey : faCloud}
      >
        Sign in with {sso ? 'your provider' : 'Amazon Connect'}{' '}
      </Button>
    </>
  );
};

const Info = () => (
  <IconConstellation leftIcon={faCirclePhone} topIcon={faEnvelope} rightIcon={faMessageSmile} centerIcon={faHeadset} />
);

export default { Main, Info };

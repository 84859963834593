import { useState } from 'react';

import { Auth } from 'aws-amplify';
import { match } from 'ts-pattern';

import { AuthState } from '../types/AuthState';
import {
  handleInitialChallenge,
  handleInsecureChallenge,
  handleMfaCodeChallenge,
  handleMfaSelectionChallenge,
  handleTenantChallenge
} from './challengeHandlers';

const USERNAME_SUFFIX = '@neon.com';

export const useAuthFlow = ({
  tenantId,
  agentConfig
}: {
  tenantId: string;
  agentConfig: connect.AgentConfiguration;
}) => {
  const [authState, setAuthState] = useState<AuthState>({ stage: 'initial' });
  const [invalidCode, setInvalidCode] = useState(false);

  const mfaDestinations =
    'obfuscatedEmail' in authState
      ? { obfuscatedEmail: authState.obfuscatedEmail, obfuscatedPhone: authState.obfuscatedPhone }
      : null;

  const username = `${tenantId}__${agentConfig?.username}${
    !agentConfig?.username.includes('@') ? USERNAME_SUFFIX : ''
  }`;

  // Auth step handlers will call back into handleAuthStep until user input is needed (mfa_select and mfa_entry)
  // at which point handle auth step will return and terminate, handing back control to React to set the auth stage
  // and allow the UI to collect more details. Needs work on naming of stages to make clearer when it should call back
  // into handleAuthStep, and when it should return.
  const handleAuthStep = async (state: AuthState): Promise<AuthState> => {
    console.log('NNDEBUG handleAuth', state);
    return match(state)
      .returnType<Promise<AuthState> | AuthState>()
      .with({ stage: 'initial' }, handleInitialChallenge({ handleAuthStep, username }))
      .with({ stage: 'insecure' }, handleInsecureChallenge({ handleAuthStep, agentConfig }))
      .with({ stage: 'tenant_id' }, handleTenantChallenge({ handleAuthStep, tenantId }))
      .with({ stage: 'mfa_select' }, (state) => state)
      .with({ stage: 'mfa_selected' }, handleMfaSelectionChallenge({ handleAuthStep, tenantId }))
      .with({ stage: 'mfa_entry' }, (state) => state)
      .with({ stage: 'mfa_entered' }, handleMfaCodeChallenge({ handleAuthStep, setInvalidCode, tenantId }))
      .with({ stage: 'complete' }, () => ({ stage: 'complete' as const }))
      .exhaustive();
  };

  const beginAuth = async () => {
    try {
      if (await Auth.currentSession()) {
        setAuthState({ stage: 'complete' });
        return;
      }
    } catch {}

    const newState = await handleAuthStep({ stage: 'initial' });
    setAuthState(newState);
  };

  const selectMfaDestination = async (mfaSelection: 'email' | 'sms') => {
    if (!('authResponse' in authState)) {
      throw `Invalid auth state: ${authState}`;
    }

    const newState = await handleAuthStep({ ...authState, stage: 'mfa_selected', mfaSelection });
    setAuthState(newState);
  };

  const provideMfaCode = async (mfaCode: string) => {
    if (!('authResponse' in authState) || !('mfaSelection' in authState)) {
      throw `Invalid auth state: ${authState}`;
    }

    setInvalidCode(false);

    const newState = await handleAuthStep({ ...authState, stage: 'mfa_entered', mfaCode });
    setAuthState(newState);
  };

  return {
    beginAuth,
    selectMfaDestination,
    provideMfaCode,

    mfaDestinations,
    invalidCode,
    authStage: authState.stage
  };
};

import { Auth } from 'aws-amplify';
import { match } from 'ts-pattern';

import { AuthState } from '../types/AuthState';

export const handleInitialChallenge =
  ({ handleAuthStep, username }: { handleAuthStep: (state: AuthState) => Promise<AuthState>; username: string }) =>
  async () => {
    const authResponse = await Auth.signIn(username);

    const { signInUserSession } = authResponse;
    if (signInUserSession) {
      return { stage: 'complete' as const };
    }

    const challengeStep = authResponse.challengeParam?.challengeName;

    return handleAuthStep(
      match(challengeStep)
        .with('TENANT_ID', () => ({ stage: 'tenant_id' as const, authResponse }))
        .with('INSECURE_SIGN_IN', () => ({ stage: 'insecure' as const, authResponse }))
        .otherwise(() => {
          throw `Unexpected challenge step: ${challengeStep}`;
        })
    );
  };

export const handleInsecureChallenge =
  ({
    handleAuthStep,
    agentConfig
  }: {
    handleAuthStep: (state: AuthState) => Promise<AuthState>;
    agentConfig: connect.AgentConfiguration;
  }) =>
  async (state: Extract<AuthState, { stage: 'insecure' }>) => {
    const { authResponse: lastAuthResponse } = state;
    const connectUserId = agentConfig.routingProfile.queues.filter((q) => q.name === null)[0].queueARN.split('/')[4];
    const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, connectUserId);
    const challengeStep = authResponse.challengeParam?.challengeName;

    return handleAuthStep(
      match(challengeStep)
        .with(undefined, () => ({ stage: 'complete' as const }))
        .otherwise(() => {
          throw `Unexpected challenge step: ${challengeStep}`;
        })
    );
  };

export const handleTenantChallenge =
  ({ handleAuthStep, tenantId }: { handleAuthStep: (state: AuthState) => Promise<AuthState>; tenantId: string }) =>
  async (state: Extract<AuthState, { stage: 'tenant_id' }>) => {
    const { authResponse: lastAuthResponse } = state;
    const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, tenantId, { tenantId });
    const challengeStep = authResponse.challengeParam?.challengeName;

    return handleAuthStep(
      match(challengeStep)
        .with('INSECURE_SIGN_IN', () => ({ stage: 'insecure' as const, authResponse }))
        .with('CHOOSE_DESTINATION', () => {
          const { obfuscatedEmail, obfuscatedPhone } = lastAuthResponse.challengeParam ?? {};
          if (!obfuscatedPhone?.length && !obfuscatedEmail?.length) {
            throw `No MFA destinations provided`;
          }

          return {
            stage: 'mfa_select' as const,
            authResponse,
            obfuscatedEmail,
            obfuscatedPhone
          };
        })
        .otherwise(() => {
          throw `Unexpected challenge step: ${challengeStep}`;
        })
    );
  };

export const handleMfaSelectionChallenge =
  ({ handleAuthStep, tenantId }: { handleAuthStep: (state: AuthState) => Promise<AuthState>; tenantId: string }) =>
  async (state: Extract<AuthState, { stage: 'mfa_selected' }>) => {
    const { authResponse: lastAuthResponse, mfaSelection } = state;
    const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, mfaSelection, { tenantId });
    const challengeStep = authResponse.challengeParam?.challengeName;

    return handleAuthStep(
      match(challengeStep)
        .with('OTP', () => ({
          stage: 'mfa_entry' as const,
          authResponse,
          mfaSelection
        }))
        .otherwise(() => {
          throw `Unexpected challenge step: ${challengeStep}`;
        })
    );
  };

export const handleMfaCodeChallenge =
  ({
    handleAuthStep,
    setInvalidCode,
    tenantId
  }: {
    handleAuthStep: (state: AuthState) => Promise<AuthState>;
    setInvalidCode: (invalid: boolean) => void;
    tenantId: string;
  }) =>
  async (state: Extract<AuthState, { stage: 'mfa_entered' }>) => {
    const { authResponse: lastAuthResponse, mfaSelection, mfaCode } = state;
    const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, `${mfaSelection}:${mfaCode}`, {
      tenantId
    });
    const challengeStep = authResponse.challengeParam?.challengeName;

    return handleAuthStep(
      match(challengeStep)
        .with('OTP', () => {
          setInvalidCode(true);
          return {
            stage: 'mfa_entry' as const,
            authResponse,
            mfaSelection
          };
        })
        .with(undefined, () => ({ stage: 'complete' as const }))
        .otherwise(() => {
          throw `Unexpected challenge step: ${challengeStep}`;
        })
    );
  };

import { AuthStage } from '../../../types/AuthState';

const Main = ({
  authStage,
  mfaDestinations,
  invalidCode,
  selectMfaDestination,
  provideMfaCode
}: {
  authStage: AuthStage;
  mfaDestinations: { obfuscatedEmail: string | undefined; obfuscatedPhone: string | undefined } | null;
  invalidCode: boolean;
  selectMfaDestination: (dest: 'email' | 'sms') => Promise<void>;
  provideMfaCode: (code: string) => Promise<void>;
}) => <>MFA Stub</>;

const Info = () => <></>;

export default { Main, Info };

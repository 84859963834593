import cx from 'classnames';
import { P, match } from 'ts-pattern';

import { useConfigContext } from 'lib/core/config';
import { useStreamsContext } from 'lib/core/context/StreamsProvider';

import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import Button from 'lib/common/components/Button';
import Drawer from 'lib/common/components/Drawer';
import Modal from 'lib/common/components/Modal';
import SupportFooter from 'lib/common/components/SupportFooter';
import Text from 'lib/common/components/Text';
import ResponsiveLogo from 'lib/common/components/atoms/ResponsiveLogo';

import useIsMediumScreen from 'lib/common/mediaQueries/useIsMediumScreen';
import getUserName from 'lib/common/utils/getUserName';

import { useAuthContext } from '../../Context';
import { AuthStage } from '../../types/AuthState';
import LoginPages from './pages';

import styles from './login.module.scss';

export default function Login({
  authStage,
  mfaDestinations,
  invalidCode,
  selectMfaDestination,
  provideMfaCode
}: {
  authStage: AuthStage;
  mfaDestinations: { obfuscatedEmail: string | undefined; obfuscatedPhone: string | undefined } | null;
  invalidCode: boolean;
  selectMfaDestination: (dest: 'email' | 'sms') => Promise<void>;
  provideMfaCode: (code: string) => Promise<void>;
}) {
  const isSoftphone = useIsSoftphoneQuery();
  const isMediumScreen = useIsMediumScreen();
  const { config, notFound } = useConfigContext();
  const { user } = useAuthContext();
  const { initializeCCP } = useStreamsContext();

  // will be removed
  const DebugLogin = () => (
    <div>
      <Text>{JSON.stringify({ authStage })}</Text>
      <Text>{JSON.stringify({ mfaDestinations })}</Text>
      <Text>{JSON.stringify({ invalidCode })}</Text>
      {match(authStage)
        .with('initial', () => (
          <Button asyncAction onClick={initializeCCP}>
            connect
          </Button>
        ))
        .with('mfa_select', () => (
          <>
            <Button asyncAction onClick={async () => selectMfaDestination('email')}>
              email
            </Button>
            <Button asyncAction onClick={async () => selectMfaDestination('sms')}>
              sms
            </Button>
          </>
        ))
        .with('mfa_entry', () => (
          <Button asyncAction onClick={async () => provideMfaCode(prompt('mfa code')!)}>
            code
          </Button>
        ))
        .otherwise(() => (
          <></>
        ))}
    </div>
  );

  const [MainPage, InfoPage] = match({ authStage, notFound })
    .with({ notFound: true }, () => [
      <LoginPages.NotFound.Main key="notFoundMain" />,
      <LoginPages.NotFound.Info key="notFoundInfo" />
    ])
    .with({ authStage: P.union('initial', 'tenant_id', 'insecure') }, () => [
      <LoginPages.Hello.Main
        name={user ? getUserName({ firstName: user.firstName }) : undefined}
        sso={Boolean(config.SSO_URL)}
        key="helloMain"
      />,
      <LoginPages.Hello.Info key="helloInfo" />
    ])
    .with({ authStage: P.string.startsWith('mfa') }, { authStage: 'complete' }, () => [
      <LoginPages.Verify.Main
        authStage={authStage}
        mfaDestinations={mfaDestinations}
        invalidCode={invalidCode}
        selectMfaDestination={selectMfaDestination}
        provideMfaCode={provideMfaCode}
        key="verifyMain"
      />,
      <LoginPages.Verify.Info key="verifyInfo" />
    ])
    .exhaustive();

  return (
    <>
      <div className={styles['login']}>
        <div className={cx(styles['login__main'], { [styles['login__main--softphone']]: isSoftphone })}>
          <div className={styles['login__main__logo']}>
            <ResponsiveLogo isSoftphone={isSoftphone} />
          </div>
          <div
            className={cx(styles['login__main__container'], {
              [styles['login__main__container--softphone']]: isSoftphone
            })}
          >
            {MainPage}
          </div>
          <SupportFooter />
        </div>
        {match({ isSoftphone, isMediumScreen })
          .with({ isSoftphone: false, isMediumScreen: false }, () => (
            <div className={styles['login__info']}>{InfoPage}</div>
          ))
          .with({ isSoftphone: true }, () => (
            <Drawer open={false} onClose={() => {}}>
              {InfoPage}
            </Drawer>
          ))
          .with({ isMediumScreen: true }, () => (
            <Modal open={false} onClose={() => {}}>
              {InfoPage}
            </Modal>
          ))
          .exhaustive()}
      </div>
    </>
  );
}

import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import 'amazon-connect-chatjs';
import 'amazon-connect-streams';
import 'amazon-connect-taskjs';
import { PrimeReactProvider } from 'primereact/api';

import ConfigProvider from 'lib/core/config';
import AuthProvider from 'lib/core/context/AuthProvider';
import IsolatedAuthProvider from 'lib/core/context/IsolatedAuthProvider';
import StreamsProvider from 'lib/core/context/StreamsProvider';

import AgentProvider from 'lib/common/contexts/AgentContext';
import AgentPreferencesProvider from 'lib/common/contexts/AgentPreferenceContext';
import EmailUploadProvider from 'lib/common/contexts/EmailUploadContext';
import InstanceProvider from 'lib/common/contexts/InstanceContext';
import ModulesProvider from 'lib/common/contexts/ModulesContext';
import OverlayProvider from 'lib/common/contexts/OverlayContext';
import PreferencesProvider from 'lib/common/contexts/PreferencesContext';
import RequirementsProvider from 'lib/common/contexts/RequirementsContext';
import DesktopLayoutProvider from 'lib/common/contexts/layout/DesktopLayoutContext';

import LoggerController from 'lib/common/components/LoggerController/LoggerController';
import Offline from 'lib/common/components/Offline';

import 'lib/common/customIcons';
import isIsolatedMode from 'lib/common/utils/isIsolatedMode';
import { IsolatedRoutes, Routes } from 'lib/routes';

import ErrorBoundary from './ErrorBoundary';

function IsolatedApp() {
  return (
    <IsolatedAuthProvider>
      <IsolatedRoutes />
    </IsolatedAuthProvider>
  );
}

function FullApp() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <PreferencesProvider>
        <StreamsProvider>
          <LoggerController>
            <AgentProvider>
              <AuthProvider>
                <AgentPreferencesProvider>
                  <ModulesProvider>
                    <InstanceProvider>
                      <RequirementsProvider>
                        <EmailUploadProvider>
                          <DesktopLayoutProvider>
                            <OverlayProvider>
                              <PrimeReactProvider value={{ unstyled: false }}>
                                <ErrorBoundary>
                                  <Routes />
                                </ErrorBoundary>
                              </PrimeReactProvider>
                            </OverlayProvider>
                          </DesktopLayoutProvider>
                        </EmailUploadProvider>
                      </RequirementsProvider>
                    </InstanceProvider>
                  </ModulesProvider>
                </AgentPreferencesProvider>
              </AuthProvider>
            </AgentProvider>
          </LoggerController>
        </StreamsProvider>
      </PreferencesProvider>
    </QueryClientProvider>
  );
}

export default function App() {
  const isIsolatedApp = isIsolatedMode();
  const children = isIsolatedApp ? <IsolatedApp /> : <FullApp />;

  return (
    <ErrorBoundary>
      <Router>
        <Offline>
          <ConfigProvider>{children}</ConfigProvider>
        </Offline>
      </Router>
    </ErrorBoundary>
  );
}
